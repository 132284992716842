import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "@fontsource/vt323";
import CSS from "csstype";
import { loginButtonStyle } from "./styles";
import { postAndThen } from "./fetchAndThen";
import { LOGIN_API_ENDPOINT } from "./endpoints";
import { Button, TextField, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { LoginAnswerType } from "./types";
import { useAppDispatch } from "./app/hooks";
import { setToken } from "./features/token/tokenSlice";

const formStyle: CSS.Properties = {
  backgroundColor: "white",
  margin: "auto"
};

interface LoginPropsType {
};

const Login = (props: LoginPropsType) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  const doLogin = () => {
    setMessage("");
    const body = {
      "email": email,
      "password": password
    };

    postAndThen(LOGIN_API_ENDPOINT, body, (response: LoginAnswerType) => {
      if(response.message !== undefined){
        setMessage(response.message);
        return;
      }
      console.log(response)
      if(response.token !== undefined) {
          dispatch(setToken(response.token));
      }
      const redirectURL = localStorage.getItem("redirectURL");
      localStorage.removeItem("redirectURL");
      console.log("Redirecting to " + redirectURL);
      if(redirectURL) navigate(redirectURL);
      else navigate("/");
    });
  };

  const onEnter = (e: any) => {
    if(e.key !== "Enter") return;
    doLogin();
  };

  return <main>
    <form>
      <Table style={formStyle}>
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField fullWidth value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={onEnter}
                label="Password" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button color="secondary" fullWidth style={loginButtonStyle} onClick={() => doLogin()}>Login</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{message}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
  </main>;
};

export default Login;
