import { useState } from "react";
import "@fontsource/vt323";
import CSS from "csstype";
import { loginButtonStyle } from "./styles";
import { postAndThen } from "./fetchAndThen";
import { REGISTER_API_ENDPOINT } from "./endpoints";
import { Button, TextField, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { RegisterAnswerType } from "./types";

const formStyle: CSS.Properties = {
  backgroundColor: "white",
  margin: "auto"
};

interface RegisterPropsType {
};

const Register = (props: RegisterPropsType) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [c_password, setC_password] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [disableFields, setDisableFields] = useState<boolean>(false);

  const doRegister = () => {
    setMessage("");
    const body = {
      "name": name,
      "email": email,
      "password": password,
      "c_password": c_password,
    };

    postAndThen(REGISTER_API_ENDPOINT, body, (response: RegisterAnswerType) => {
      if(response.message !== undefined){
        setMessage(response.message);
        if(response.message === "Account created. Please check your email for verification link.") {
          setDisableFields(true);
        }
        return;
      }
    });
  };

  const onEnter = (e: any) => {
    if(e.key !== "Enter") return;
    doRegister();
  };

  return <main>
    <form>
      <Table style={formStyle}>
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField fullWidth value={name} onChange={(e) => setName(e.target.value)} label="Name" disabled={disableFields} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField fullWidth value={email} onChange={(e) => setEmail(e.target.value)} label="Email" disabled={disableFields} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={disableFields}
                label="Password" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                type="password"
                fullWidth
                value={c_password}
                onChange={(e) => setC_password(e.target.value)}
                onKeyUp={onEnter}
                disabled={disableFields}
                label="Confirm password" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button color="secondary" fullWidth style={loginButtonStyle} onClick={() => doRegister()} disabled={disableFields} >Register</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{message}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
  </main>;
};

export default Register;
