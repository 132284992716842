import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "@fontsource/vt323";
import CSS from "csstype";
import { getAndThen } from "./fetchAndThen";
import { VERIFY_API_ENDPOINT } from "./endpoints";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { VerifyAnswerType } from "./types";

const tableStyle: CSS.Properties = {
  backgroundColor: "white",
};

interface EmailVerifyPropsType {
};

const EmailVerify = (props: EmailVerifyPropsType) => {
  const { id, hash } = useParams();
  const [message, setMessage] = useState<string>("");
  const queryParams = new URLSearchParams(window.location.href);
  const navigate = useNavigate();

  const doVerify = () => {
    setMessage("Verifying account");

    const url = VERIFY_API_ENDPOINT + '/' + id + '/' + hash + '?' + queryParams.toString();

    getAndThen(url, (res: VerifyAnswerType) => {
      setMessage(res.message);
      if(res.message === "Unauthenticated.") {
        localStorage.setItem("redirectURL", window.location.pathname);
        navigate("/login");
      } else {
        setTimeout(() => navigate("/"), 3000);
      }
    });
  };

  useEffect(() => {
    doVerify();
  }, []);

  return <main>
      <Table style={tableStyle}>
        <TableBody>
          <TableRow>
            <TableCell style={{textAlign: "center"}}><h2>{message}</h2></TableCell>
          </TableRow>
        </TableBody>
      </Table>
  </main>;
};

export default EmailVerify;
