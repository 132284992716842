import CSS from "csstype";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { vaporBlue } from "../../styles";
import { useGetUserQuery, useUpdateUserMutation, useDeleteUserMutation } from './userAPI';
import { Button, Table, TableBody, TableRow, TextField } from "@mui/material";
import TableCell from "../../TableCell";

interface UserPropsType {
};

const userStyle: CSS.Properties = {
  color: vaporBlue,
  marginTop: "2%",
  backgroundColor: "white",
};

const User = (props: UserPropsType) => {
  const { id } = useParams();
  const [ deleteUser ] = useDeleteUserMutation();
  const [ editing, setEditing ] = useState<boolean>(false);
  const [ editName, setEditName ] = useState<string>("");
  const [ editEmail, setEditEmail ] = useState<string>("");

  const {
    data,
    isError,
    error,
  } = useGetUserQuery(id!, { pollingInterval: 60000 });

  const [
    updateUser,
  ] = useUpdateUserMutation();

  return <main style={userStyle}>
    <Table>
      <TableBody>
        {isError && <TableRow>
          <TableCell colSpan={2}>
           {('status' in error!) ? error?.status : error?.message}
          </TableCell>
        </TableRow>}
        {data && <>
          <TableRow>
            <TableCell>
              <Button onClick={() => {
                if(editing) {
                  updateUser({ id: data.id, name: editName, email: editEmail })
                  setEditing(false);
                  setEditEmail("");
                  setEditName("");
                } else {
                  setEditing(true);
                  setEditEmail(data.email);
                  setEditName(data.name);
                }
              }}>{editing ? 'Save' : 'Edit'}</Button>
            </TableCell>
            <TableCell>
              <Button style={{color: "red", float: "right"}} onClick={() => deleteUser(data.id)}>Delete</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              {editing ?
                <TextField value={editEmail} onChange={(e) => setEditEmail(e.target.value)} ></TextField> :
                data.email
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>
              {editing ?
                <TextField value={editName} onChange={(e) => setEditName(e.target.value)} ></TextField> :
                data.name
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registered</TableCell>
            <TableCell>{new Date(data.created_at).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Verified</TableCell>
            <TableCell>{data.email_verified_at ? new Date(data.email_verified_at).toLocaleString() : "Unverified"}</TableCell>
          </TableRow>
        </>}
      </TableBody>
    </Table>
  </main>;
};

export default User;
