import { useState, useEffect, SyntheticEvent } from "react";
import { Card, CardMedia, CardContent, CardActionArea, CardActions } from "@material-ui/core";
import { Rating, Typography } from "@mui/material";
import { contentStyle } from "./styles";
import { GameType } from "./types";

interface BlurbPropsType extends GameType {
};

const Blurb = (props: BlurbPropsType) => {
  const imageHeight: number = 540;
  const imageWidth: number = 960;

  const [imageUrl, setImageUrl] = useState<string>();
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [rating, setRating] = useState<number>(props.rating);

  const visitProfile = () => window.location.href = "/game/" + encodeURI(props.name);

  const changeRating = (event: SyntheticEvent<Element, Event>, newValue: number | null) => {
    if(newValue === null) return;
    setRating(newValue);
  };

  useEffect(() => {
    if(!props.images.length) return;
    const interval = setInterval(() => {
      if(imageIndex === (props.images.length - 1)) {
        setImageIndex(0);
      } else { 
        setImageIndex(imageIndex + 1);
      }
    }, 10000 + Math.floor(Math.random() * 10000));

    return () => {
      clearInterval(interval);
    };
  }, [imageIndex, setImageIndex, props.images]);

  useEffect(() => {
    if(props.images.length) setImageUrl(props.images[imageIndex].url);
  }, [imageIndex, setImageUrl, props.images]);

  return <Card style={contentStyle}>
    <CardActionArea onClick={visitProfile}>
      {imageUrl && <CardMedia
        component="img"
        height={imageHeight + "px"}
        width={imageWidth + "px"}
        image={imageUrl}
      />}
      <CardContent>
        <Typography variant="h1">{props.name}</Typography>
        <Typography>{props.description}</Typography>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <Typography>
        <Rating value={rating} onChange={changeRating} precision={0.5} readOnly />
      </Typography>
    </CardActions>
  </Card>;
};

export default Blurb;
