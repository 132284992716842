import { useEffect, useState } from "react";
import CSS from "csstype";
import { vaporBlue } from "./styles";
import Blurb from "./Blurb";
import { GameType } from "./types";
import { GAMES_API_ENDPOINT } from "./endpoints";
import { getAndThen } from "./fetchAndThen";

const mainStyle: CSS.Properties = {
  color: vaporBlue,
  marginTop: "2%",
};

interface MainPropsType {
};

const Main = (props: MainPropsType) => {
  const [games, setGames] = useState<GameType[]>();

  useEffect(() => {
    getAndThen(GAMES_API_ENDPOINT, setGames);
  }, [setGames]);

  return <main style={mainStyle}>
    {games && games.map((game, i) => <Blurb key={i} {...game} />)}
  </main>;
};

export default Main;
