import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tokenReducer from "../features/token/tokenSlice";
import { userApi } from '../features/user/userAPI';

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});

store.subscribe(() => {
    const state = store.getState();
    localStorage.setItem("token", state.token.value);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
