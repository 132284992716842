import CSS from "csstype";
import { Link, useNavigate } from "react-router-dom";
import { vaporBlue } from "../../styles";
import { UserType } from "../../types";
import { useGetUsersQuery, useDeleteUserMutation } from './userAPI';
import { Button, Table, TableBody, TableHead, TableRow } from "@mui/material";
import TableCell from "../../TableCell";

interface UserPropsType extends UserType {
};

const User = (props: UserPropsType) => {
  const [ deleteUser ] = useDeleteUserMutation();
  const navigate = useNavigate();

  return <TableRow>
      <TableCell>{props.email}</TableCell>
      <TableCell>{props.name}</TableCell>
      <TableCell>
        <Button onClick={() => navigate("/admin/users/" + props.id)}>
          View
        </Button>
      </TableCell>
  </TableRow>;
};

const usersStyle: CSS.Properties = {
  color: vaporBlue,
  marginTop: "2%",
  backgroundColor: "white",
};

interface UsersPropsType {
};

const Users = (props: UsersPropsType) => {
  const {
    data,
    isError,
    error,
  } = useGetUsersQuery(null, { pollingInterval: 60000 });

  return <main style={usersStyle}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isError && <TableRow>
          <TableCell colSpan={4}>
           {('status' in error!) ? error?.status : error?.message}
          </TableCell>
        </TableRow>}
        {data?.map((user: UserType, i: number) => <User key={i} {...user} />)}
      </TableBody>
    </Table>
  </main>;
};

export default Users;
