import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { contentStyle } from "./styles";
import { Typography } from "@mui/material";
import CSS from "csstype";
import { GameType } from "./types";
import { getAndThen } from "./fetchAndThen";
import { GAMES_API_ENDPOINT } from "./endpoints";

interface GameProfilePropsType {
}

const articleStyle: CSS.Properties = {
  ...contentStyle,
  backgroundColor: "white",
};

const GameProfile = (props: GameProfilePropsType) => {
  const name = useParams().name;
  const [game, setGame] = useState<GameType>();

  useEffect(() => {
    getAndThen(GAMES_API_ENDPOINT + "/" + name, setGame);
  }, [setGame, name]);

  return <article style={articleStyle}>
    <Typography variant="h1" style={{textAlign: "center"}}>{name}</Typography>
    <section>
      <Typography variant="h3">
        {game && game.description!.split("\n").map((line: string, i: number) => <div key={i}>{line}</div>)}
      </Typography>
    </section>
  </article>;
};

export default GameProfile;
