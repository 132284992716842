import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Login from "./Login";
import Main from "./Main";
import GameProfile from "./GameProfile";
import EmailVerify from "./EmailVerify";
import Register from "./Register";
import Users from "./features/user/Users";
import User from "./features/user/User";
import { contentStyle } from "./styles";

interface AppPropsType {
};

const App = (props: AppPropsType) => {
  return <div style={contentStyle}>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/game/:name" element={<GameProfile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/email/verify/:id/:hash" element={<EmailVerify />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/users/:id" element={<User />} />
      </Routes>
    </BrowserRouter>
  </div>;
};

export default App;
