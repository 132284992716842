import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import CSS from "csstype";
import "@fontsource/vt323";
import { vaporBlue, vaporPink, loginButtonStyle } from "./styles";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "./app/hooks";
import { selectToken, setToken } from "./features/token/tokenSlice";

const headerStyle: CSS.Properties = {
  height: "220px",
  color: vaporBlue,
  backgroundColor: "#111",
  margin: "auto",
};

const titleStyle: CSS.Properties = {
  fontSize: "128px",
  color: vaporPink,
  textAlign: "center",
  fontFamily: "VT323",
};

const navStyle: CSS.Properties = {
  textAlign: "center",
};

interface HeaderPropsType {
};

const Header = (props: HeaderPropsType) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();

  const logout = () => {
    dispatch(setToken(""));
    navigate("/");
  };

  return <header style={headerStyle}>
    <Link to="/" style={{textDecoration: "none"}}>
     <Typography variant="h1" style={titleStyle}>
        vrmmo.network
      </Typography>
    </Link>
    <nav style={navStyle}>
      {token !== "" ?
      <Button onClick={logout} variant="contained" color="inherit" style={loginButtonStyle}>Logout</Button> :
      <>
        <Link to="/register" style={{textDecoration: "none"}}>
          <Button
            variant="contained"
            color="inherit"
            style={{...loginButtonStyle, marginRight: "2%"}}
          >
            Register
          </Button>
        </Link>
        <Link to="/login" style={{textDecoration: "none"}}>
          <Button
            variant="contained"
            color="inherit"
            style={loginButtonStyle}
          >
            Login
          </Button>
        </Link>
      </>}
    </nav>
  </header>;
};

export default Header;
