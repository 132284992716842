import CSS from "csstype";

export const vaporBlue: string = "#01cdfe";
export const vaporPink: string = "#ff71ce";
export const vaporYellow: string = "#fffb96";

export const fontStyle: CSS.Properties = {
  fontSize: "18px"
};

export const contentStyle: CSS.Properties = {
  width: "960px",
  marginBottom: "3%",
  marginLeft: "auto",
  marginRight: "auto",
};

export const loginButtonStyle: CSS.Properties = {
  fontSize: "36px",
  fontFamily: "VT323",
  height: "42px",
  backgroundColor: vaporBlue,
  color: vaporYellow,
};
